<template>
  <div
    v-if="isEndDateOutOfBounds"
    class="payins-table-message"
  >
    <bg-icon
      size="sm"
      name="open-folder"
      class="payins-table__gears-icon"
    />
    <bg-text
      type="h6"
      color="grey-dark"
      weight="bold"
      class="payins-table__empty-title"
    >
      Dados disponíveis a partir de Janeiro de 2024
    </bg-text>
    <bg-text
      type="body-md"
      color="grey-semi-dark"
    >
      A interface exibe dados a partir de Janeiro de 2024.
      Gostaria de dados anteriores? Entre em contato com
      o suporte para entendermos suas necessidades.
    </bg-text>
  </div>
  <tr-table
    v-else
    :disable-click="!fullVersion"
  >
    <tr-thead>
      <tr-tr>
        <tr-th width="15%">
          Status
        </tr-th>
        <tr-th width="15%">
          Data
          <bg-icon
            v-tooltip="`
              A data exibida varia conforme o método: </br></br>

              <b>Cartão de crédito</b>: Data da transação
              </br>
              <b>Boleto</b>: Data da emissão
              </br>
              <b>QR Code</b>: Data da criação
            `"
            style="vertical-align: sub;"
            name="info"
            size="sm"
          />
        </tr-th>
        <tr-th :width="fullVersion ? '18%' : '15%'">
          Data de recebimento
        </tr-th>
        <tr-th width="18%">
          Pagador
        </tr-th>
        <template v-if="fullVersion">
          <tr-th width="15%">
            Valor original
          </tr-th>
          <tr-th width="15%">
            Valor recebido
          </tr-th>
        </template>
        <template v-else>
          <tr-th width="15%">
            Documento
          </tr-th>
          <tr-th width="15%">
            Valor
          </tr-th>
          <tr-th width="15%">
            e2e_id
          </tr-th>
        </template>
        <tr-th width="5%">
          Método
        </tr-th>
      </tr-tr>
    </tr-thead>
    <tr-tbody>
      <tr-tr
        v-for="payin in payins"
        :key="payin.id"
        :class="['payins-table__row', { 'payins-table__row--clickable': fullVersion }]"
        @click="fullVersion && onOpenDetailsDialog(payin.id)"
      >
        <tr-td>
          <status-badge
            :status="payin.status"
            badge-size="sm"
            icon-size="sm"
          />
        </tr-td>
        <tr-td>
          {{ formatDateTime(payin.created_at, formatDateTimeShape) || '---' }}
        </tr-td>
        <tr-td>
          <payin-received-at
            :received-at="payin.received_at"
            :payment-method="payin.payment_type"
          />
        </tr-td>
        <tr-td>
          {{ payin.payer ? payin.payer.name : '---' }}
        </tr-td>
        <template v-if="fullVersion">
          <tr-td>
            <bg-text>
              {{ payin.original_amount ? centsToReal(payin.original_amount) : '---' }}
            </bg-text>
            <bg-text
              v-if="payin.details?.installments"
              weight="regular"
              type="label"
            >
              {{
                buildInstallmentDescription({
                  amount: payin.original_amount,
                  installments: payin.details.installments
                })
              }}
            </bg-text>
          </tr-td>
          <tr-td>
            <bg-text>
              {{ payin.received_amount ? centsToReal(payin.received_amount) : '---' }}
            </bg-text>
          </tr-td>
        </template>
        <template v-else>
          <tr-td>
            <bg-text>
              {{ obfuscateAndFormatCPF(payin.payer?.tax_id) }}
            </bg-text>
          </tr-td>
          <tr-td>
            <bg-text>
              {{ payin.received_amount ? centsToReal(payin.received_amount) : '---' }}
            </bg-text>
          </tr-td>
          <tr-td>
            <div
              v-if="payin.end_to_end_id"
              style="display: inline-flex; align-items: center; gap: 4px;"
            >
              <bg-text>
                {{ middleTruncateWithEllipsis(payin.end_to_end_id, 8) }}
              </bg-text>
              <copy-clipboard-button
                type="inline"
                :text-to-copy="payin.end_to_end_id"
              />
            </div>
            <bg-text v-else>
              ---
            </bg-text>
          </tr-td>
        </template>
        <tr-td
          class="payins-table-td__payment-method"
          align="right"
        >
          <bg-badge
            v-if="!!payin.payment_link_id"
            v-tooltip.top-center="`Pagamento via Link de Pagamento`"
            color="grey-light"
            size="sm"
          >
            <bg-icon
              size="sm"
              name="payment-link"
              color="grey-semi-dark"
              class="payins-table__payment-link-icon"
            />
          </bg-badge>
          <payins-payment-method-icon :payment-method="payin.payment_type" />
        </tr-td>
        <tr-td>
          <div class="payins-table__actions-container">
            <button
              v-if="fullVersion"
              v-tooltip.top-center="receiptButtonStatus(payin)"
              :disabled="!isReceivedAndReceiptAvailable(payin)"
              class="reset-button"
              aria-label="Comprovante de recebimento"
              @click.stop="getReceipt(payin.receipt_file_url)"
            >
              <bg-icon
                size="sm"
                name="bank"
                :color="isReceivedAndReceiptAvailable(payin) ? 'blue-normal' : 'grey-semi-light'"
                class="payins-table__actions-icon"
              />
            </button>
            <button
              v-if="fullVersion"
              v-tooltip.top-center="refundButtonStatus(payin)"
              class="reset-button"
              :disabled="!isRefundable(payin)"
              aria-label="Devolver"
              @click.stop="onOpenRefundDialog(payin)"
            >
              <bg-icon
                size="sm"
                name="refund"
                :color="isRefundable(payin) ? 'blue-normal' : 'grey-semi-light'"
                class="payins-table__actions-icon"
              />
            </button>
            <button
              v-if="fullVersion"
              v-tooltip.top-center="'Ver mais'"
              class="reset-button"
              aria-label="Ver mais"
              @click.stop="onOpenDetailsDialog(payin.id)"
            >
              <bg-icon
                size="sm"
                name="eye"
                color="blue-normal"
                class="payins-table__actions-icon"
              />
            </button>
          </div>
        </tr-td>
      </tr-tr>
    </tr-tbody>
  </tr-table>
</template>

<script setup>
import { BgIcon, BgText, BgBadge } from '@transfeeradev/bridge';
import StatusBadge from '../../components/StatusBadge.vue';
import { formatDateTime } from '@/commons/formatters/formatDate';
import formatDateTimeShape from '@/apps/payin/payins/constants/formatDateTimeShape';
import { refundButtonStatus } from '@/apps/payin/payins/constants/refundButtonStatus';
import {
  receiptButtonStatus,
  isReceivedAndReceiptAvailable
} from '@/apps/payin/payins/constants/payinReceiptButtonStatus';
import { centsToReal } from '@/commons/formatters/currency';
import { safeDivide } from '@/apps/payin/payins/helper/math';
import PayinReceivedAt from '@/apps/payin/payins/components/PayinReceivedAt.vue';
import PayinsPaymentMethodIcon from '@/apps/payin/payins/components/PayinsPaymentMethodIcon.vue';
import { middleTruncateWithEllipsis } from '@/commons/formatters/string';
import CopyClipboardButton from '@/commons/components/container/CopyClipboardButton.vue';
import { obfuscateAndFormatCPF } from '@/commons/formatters/cpfCnpj';

defineProps({
  payins: {
    required: true,
    type: Array
  },
  isEndDateOutOfBounds: {
    type: Boolean
  },
  onOpenRefundDialog: {
    type: Function
  },
  onOpenDetailsDialog: {
    type: Function
  },
  fullVersion: {
    type: Boolean,
    default: true
  }
});

const getReceipt = url => {
  window.open(url, '_blank');
};

const buildInstallmentDescription = ({ installments, amount }) => {
  return installments > 1 ? `${installments}x ${centsToReal(safeDivide(amount, installments))}` : ' à vista';
};

const isRefundable = payin => payin?.refundable_amount > 0;
</script>

<style scoped>
table {
  margin-top: var(--bg-spacing-sm) !important;
}

.payins-table-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--bg-spacing-lg);
  gap: var(--bg-spacing-sm);
  max-width: 400px;
  margin-inline: auto;

  & p,
  h6 {
    text-align: center;
  }

  & h6 span {
    display: block;
  }
}

.payins-table__empty-title {
  max-width: 275px;
}

.payins-table__row--clickable {
  cursor: pointer;
}

.payins-table__row:not(.payins-table__row--clickable):hover {
  &:nth-child(odd) {
    background-color: var(--bg-bg-background);
  }

  &:nth-child(even) {
    background-color: initial;
  }
}

.payins-table__status-icon {
  margin-left: var(--bg-spacing-2x-sm);
}

.payins-table__actions-container {
  display: flex;
}

.payins-table__actions-container button {
  display: flex;
  align-items: center;
  padding: var(--bg-spacing-2x-sm);
}

.payins-table__actions-container button:nth-last-child(1) {
  margin-left: var(--bg-spacing-2x-sm);
}

.payins-table__actions-icon:focus {
  outline: none;
}

.payins-table-td__payment-method {
  display: flex;
  align-items: center;
  gap: var(--bg-padding-sm);
  justify-content: flex-end;
}

.payins-table__gears-icon {
  height: 40px;
  width: 50px;
}
</style>
